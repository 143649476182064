<template>
  <div class='editing-access'>
    <h2 class='mb-2 text-lg font-semibold uppercase text-gray-800'>Access</h2>
    <el-switch
      v-model='shared'
      active-text='공용'
      inactive-text='개인용'>
    </el-switch>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'DashboardViewEditingAccess',
  computed: {
    ...mapFields('dashboardViews', [
      'currentDashboardView.shared'
    ]),
  },
}
</script>
