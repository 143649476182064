<template>
  <div>
    <h2 class='text-lg font-semibold uppercase text-gray-800'>Fields to Display</h2>
    <label
      v-if='isCardView'
      class='block py-2 mb-4 text-sm text-gray-500 font-light normal-case cursor-pointer hover:bg-gray-200'>
      <input type='checkbox'
        class='form-checkbox mr-2'
        :name='show_empty_display_on_values'
        :value='show_empty_display_on_values'
        v-model='show_empty_display_on_values'>
        <span>Show fields with empty values on cards</span>
    </label>
    <table class='mt-2'>
      <thead>
        <tr class='uppercase text-gray-600 text-sm'>
          <th class='py-3 pl-2 pr-12' colspan='2'>Field</th>
          <th class='py-3 pl-3 pr-12'>
            <input type='checkbox' class='form-checkbox mr-1' v-model='sidepanelSelectAll'/> Sidepanel
          </th>
          <th class='py-3 pl-3 whitespace-no-wrap'>
            {{showOnItemLabel}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if='isListView' class='border-t text-sm text-gray-800 hover:bg-gray-100'>
          <td class='py-3 pl-2'>{{nameLabel}}</td>
          <td class='py-3 pl-2 pr-12 text-gray-500 uppercase'></td>
          <td class='py-3 pl-2 pr-4'></td>
          <td class='pl-2'>
            <label class='p-3 text-left text-gray-500 font-light cursor-pointer hover:bg-gray-200'>
              <input type='checkbox'
                class='form-checkbox mr-1'
                :name='nameLabel'
                v-model='show_list_view_name'>
                <span class='text-xs whitespace-no-wrap'>{{showOnItemLabel}}</span>
            </label>
          </td>
        </tr>
        <tr v-for='fieldTemplate in customFieldsForDisplay'
            :key='`dashboard-view-visible-fields-row-${fieldTemplate.id}`'
            class='border-t text-sm text-gray-800 hover:bg-gray-100'>
          <td class='py-3 pl-2'>
            {{ fieldTemplate.custom_field_name }}
          </td>
          <td class='py-3 pl-2 pr-12 text-gray-500 uppercase'>
            {{ fieldTemplate.field_type }}
          </td>
          <td class='py-3 pl-2 pr-4'>
            <label class='p-3 text-left text-gray-500 font-light cursor-pointer hover:bg-gray-200'>
              <input type='checkbox'
                class='form-checkbox mr-1'
                :name='fieldTemplate.custom_field_name'
                :value='fieldTemplate.id'
                v-model='mapping_field_template_ids'>
                <span class='text-xs whitespace-no-wrap'>Sidepanel</span>
            </label>
          </td>
          <td class='pl-2'>
            <label class='p-3 text-left text-gray-500 font-light cursor-pointer hover:bg-gray-200'
                   v-if='showByViewLayout(fieldTemplate.field_type)'>
              <input type='checkbox'
                class='form-checkbox mr-1'
                :name='fieldTemplate.custom_field_name'
                :value='fieldTemplate.id'
                v-model='display_on_card_template_ids'>
                <span class='text-xs whitespace-no-wrap'>{{showOnItemLabel}}</span>
            </label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'DashboardViewEditingVisibleFields',
  data () {
    return {
      sidepanelSelectAll: false,
    }
  },
  watch: {
    sidepanelSelectAll: {
      handler: function(newVal) {
        if (this.isListView) {
          this.show_list_view_name = newVal
        }
        this.mapping_field_template_ids = newVal ? this.customFieldsForDisplay.map(f => f.id) : []
      }
    }
  },
  computed: {
    ...mapGetters('customFields', [
      'customFieldsForDisplay',
    ]),
    ...mapFields('dashboardViews', [
      'currentDashboardView.mapping_field_template_ids',
      'currentDashboardView.display_on_card_template_ids',
      'currentDashboardView.calendar_field_template_ids',
      'currentDashboardView.show_empty_display_on_values',
      'currentDashboardView.view_layout',
      'currentDashboardView.show_list_view_id',
      'currentDashboardView.show_list_view_name',
    ]),
    isCardView () {
      return this.view_layout === 'Card'
    },
    isListView () {
      return this.view_layout === 'List View' ||
             this.view_layout === 'List Edit'
    },
    nameLabel () {
      return '명칭'
    },
    showOnItemLabel () {
      switch (this.view_layout) {
        case 'Card':
        case 'Monthly':
        case 'Weekly':
          return 'Show On Card'
        case 'List Edit':
        case 'List View':
          return 'Include Column'
        default:
          return '!'
      }
    },
  },
  methods: {
    showByViewLayout (fieldType) {
      return fieldType !== 'header' &&
             fieldType !== 'sections'
    }
  }
}
</script>
