<template>
  <div>
    <h2 class='mb-2 text-lg font-semibold uppercase text-gray-800'>Tab</h2>
    <div class='w-full flex-grow-0 flex-shrink-0 flex flex-row justify-start items-center bg-gray-100'>
      <select
        v-model='tab_type'
        class='form-select flex-grow max-w-sm'>
        <option
          v-for='tabType in selectableTabTypes'
          :key='`tab-field-${tabType}`'
          :value='tabType'>
          {{tabType}}
        </option>
      </select>
      <select v-if='isCustomFieldTabType'
        v-model='tab_custom_field_template_id'
        class='form-select flex-grow flex-shrink-0'>
        <option
          v-for='customField in selectableCustomFields'
          :key='`tab-field-${customField.id}`'
          :value='customField.id'>
          {{customField.custom_field_name}}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'DashboardViewEditingTabConfig',
  computed: {
    ...mapState('customFields', [
      'customFieldTemplates',
    ]),
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    ...mapGetters('customFields', [
      'customFieldTemplateFromId',
    ]),
    ...mapFields('dashboardViews', [
      'currentDashboardView.tab_type',
      'currentDashboardView.tab_custom_field_template_id',
    ]),
    selectableTabTypes () {
      return [
        'none',
        'status',
        'custom field',
      ]
    },
    isCustomFieldTabType () {
      return this.currentDashboardView.tab_type === 'custom field'
    },
    selectableCustomFieldTypes () {
      return [
        'dropdown',
      ]
    },
    hasCustomFieldTemplates () {
      return this.customFieldTemplates.length > 0
    },
    selectableCustomFields () {
      let selectableCustomFields = []
      if (this.currentDashboardView.mapping_field_template_ids.length > 0) {
        selectableCustomFields = this.customFieldTemplates.filter(template => {
          return this.selectableCustomFieldTypes.includes(template.field_type)
        })
      }
      return selectableCustomFields
    },
  },
  methods: {
  },
}
</script>
