<template>
  <div class='flex flex-row justify-between w-full bg-gray-100 pl-16 pr-8 py-4 text-sm shadow-lg'>
    <div>
      <button class='inline-block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md' style='background-color: #223645;'
        @click='saveDashboard'>
        저장
      </button>
      <button class='inline-block mx-4 px-4 py-3 bg-transparent text-gray-600 hover:text-gray-900 border border-transparent rounded-md hover:border-gray-600 hover:shadow-md'
        name='close-sidepanel-btn'
        @click='sidepanelClose'>
        취소
      </button>
    </div>
    <button v-if='!isCurrentDashboardNew'
      name='show-confirm-delete-btn'
      class='block px-4 py-3 text-red-700 border border-transparent hover:border-red-400 rounded-md hover:shadow-md'
      @click='deleteDashboard'>
      삭제
    </button>
  </div>
</template>

<script>

import {  mapActions, mapGetters } from 'vuex'


export default {
  name: 'DashboardViewEditingSave',
  computed: {
    ...mapGetters('dashboardViews', [
      'isCurrentDashboardNew',
    ]),
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('dashboardViews', [
      'saveNewOrExistingDashboard',
      'deleteDashboardView',
      'updateDashboardInvestments'
    ]),
    deleteDashboard () {
      this.deleteDashboardView().then(() => {
        this.sidepanelClose()
      })
    },
    saveDashboard () {
      this.saveNewOrExistingDashboard().then(() => {
        this.updateDashboardInvestments()
        this.sidepanelClose()
      })
    }
  }
}
</script>
